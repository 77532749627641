




































import { Component, Prop, PropSync } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { Inject } from '../../../../extensions'
import { JobOffer } from '../../../../models'

import { SearchOptionType } from '../../../molecules/Search/Search.config'

import { AbstractModuleUiPresentation } from '../../_abstract'

import { SearchTileDefinition, SearchResultsModuleContent } from '../SearchResults.contracts'
import { JobOffersModuleContent } from '../../JobOffers'
import { ResolvedSearchAddon } from '../../Hero/addons'

/**
 * Presentational component for the `SearchResultsModuleUi`.
 *
 * @emits update:currentPage - When the User changes the page.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<SearchResultsModuleUiPresentation>({
  name: 'SearchResultsModuleUiPresentation',
  components: {
    SearchHeader: () => import(
      /* webpackChunkName: "modules/SearchResults" */
      './partials/SearchHeader/SearchHeader.vue'
    ),
    SearchResultTile: () => import(
      /* webpackChunkName: "modules/SearchResultTile" */
      './partials/SearchResultTile/SearchResultTile.vue'
    ),
    JobOffersTable: () => import(
      /* webpackChunkName: "molecules/JobOffersTable" */
      '../../../molecules/JobOffersTable/JobOffersTable.vue'
    ),
    NoResultsInfo: () => import(
      /* webpackChunkName: "modules/SearchResults" */
      './partials/NoResultsInfo/NoResultsInfo.vue'
    ),
    Pagination: () => import(
      /* webpackChunkName: "molecules/Pagination" */
      '../../../molecules/Pagination/Pagination.vue'
    )
  }
})
export class SearchResultsModuleUiPresentation extends AbstractModuleUiPresentation {
  @Inject(EventbusType)
  private readonly eventBus!: IEventbus

  /**
   * Currently-active page.
   */
  @PropSync('currentPage', { type: Number, required: false })
  public _currentPage?: number

  /**
   * @see SearchResultsModuleContent.headingContent
   */
  @Prop({ type: String, required: false })
  public readonly headingContent?: SearchResultsModuleContent['headingContent']

  /**
   * @see SearchResultsModuleContent.headingLevel
   */
  @Prop({ type: Number, required: false, default: 2 })
  public readonly headingLevel?: SearchResultsModuleContent['headingLevel']

  /**
   * @see SearchResultsModuleContent.intro
   */
  @Prop({ type: String, required: false })
  public readonly intro?: SearchResultsModuleContent['intro']

  /**
   * Determines whether the offers are being fetched at the very given moment.
   */
  @Prop({ type: Boolean, required: true, default: false })
  public readonly loading!: boolean

  /**
   * Array of job offers to render.
   */
  @Prop({ type: Array, required: true })
  public readonly offers!: JobOffer[]

  /**
   * @see JobOffersModuleContent.openLinkInNewTab
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly openLinkInNewTab?: JobOffersModuleContent['openLinkInNewTab']

  /**
   * Total number of search results.
   */
  @Prop({ type: Number, required: true })
  public readonly total!: number

  /**
   * Total number of elements per page.
   */
  @Prop({ type: Number, required: false, default: 10 })
  public readonly perPage!: number

  /**
   * Array of departments
   */
  @Prop({ type: Array, required: true })
  public readonly departments!: ResolvedSearchAddon['departments']

  /**
   * Array of locations
   */
  @Prop({ type: Array, required: true })
  public readonly locations!: ResolvedSearchAddon['locations']

  /**
   * Array of jobsModels
   */
  @Prop({ type: Array, required: true })
  public readonly jobsModels!: ResolvedSearchAddon['jobsModels']

  /**
   * Determines whether there are any offers to render.
   */
  public get hasOffers (): boolean {
    return typeof this.offers !== 'undefined' &&
      Array.isArray(this.offers) &&
      this.offers.length > 0
  }

  /**
   * Determines whether the search results can be paginated.
   */
  public get hasPagination (): boolean {
    return typeof this.total === 'number' && typeof this.perPage === 'number' &&
      this.totalPages > 1
  }

  /**
   * Calculate total numbers of pages.
   */
  public get totalPages (): number {
    return Math.ceil(this.total / this.perPage)
  }

  public get tiles (): Array<SearchTileDefinition> {
    return [
      ...this.departmentsTiles,
      ...this.regionTiles,
      ...this.jobModelsTiles
    ]
  }

  public get removeTile (): Omit<SearchTileDefinition, 'type'> {
    return {
      label: this.$t('modules.components.modules.GlobalSearchResults.clearFilters').toString(),
      value: 'clear'
    }
  }

  public get queryTile (): SearchTileDefinition | undefined {
    const hasTextQuery = !!this.$route.query.q && this.$route.query.q.length > 0
    if (!hasTextQuery) {
      return
    }

    return {
      label: this.$route.query.q as string,
      value: this.$route.query.q,
      type: SearchOptionType.TextQuery
    }
  }

  /**
   * Composes departments tiles from query
   */
  public get departmentsTiles (): Array<SearchTileDefinition> {
    const hasDepartmentsQuery = !!this.$route.query.d && this.$route.query.d.length > 0
    if (!hasDepartmentsQuery) {
      return []
    }

    const currentDepartments = (this.$route.query.d as string).split(',')
    return this.departments
      .filter((department) => currentDepartments.filter((value) => value === department.value).length > 0)
      .map((department) => {
        return {
          ...department,
          label: this.rawDepartmentLabelTile(this.rawDepartmentChildLabelTile(department.label)),
          type: SearchOptionType.Departments
        }
      })
  }

  /**
   * Composes regions tiles from query
   */
  public get regionTiles (): Array<SearchTileDefinition> {
    const hasRegionsQuery = !!this.$route.query.l && this.$route.query.l.length > 0
    if (!hasRegionsQuery) {
      return []
    }

    const currentLocations = (this.$route.query.l as string).split(',')
    return this.locations
      .filter((location) => currentLocations.filter((value) => value === location.value).length > 0)
      .map((model) => {
        return {
          ...model,
          type: SearchOptionType.Locations
        }
      })
  }

  /**
   * Composes jobModels tiles from query
   */
  public get jobModelsTiles (): Array<SearchTileDefinition> {
    const hasJobModelsQuery = !!this.$route.query.m && this.$route.query.m.length > 0
    if (!hasJobModelsQuery || !this.jobsModels) {
      return []
    }

    const currentJobModels = (this.$route.query.m as string).split(',')
    return this.jobsModels
      .filter((model) => currentJobModels.filter((value) => value === model.value).length > 0)
      .map((model) => {
        return {
          ...model,
          type: SearchOptionType.JobModel
        }
      })
  }

  /**
   * Removes value from query
   */
  public async handleRemoveItem (tile: SearchTileDefinition): Promise<void> {
    let d = this.$route.query.d
    let l = this.$route.query.l
    let m = this.$route.query.m
    let q = this.$route.query.q

    const appliedDepartments = d ? (d as string).split(',') : null
    if (appliedDepartments) {
      const targetDepartments = appliedDepartments.filter((department) => department !== tile.value)
      d = targetDepartments.length === 0 ? '' : targetDepartments.join(',')
    }

    const appliedLocations = l ? (l as string).split(',') : null
    if (appliedLocations) {
      const targetLocations = appliedLocations.filter((location) => location !== tile.value)
      l = targetLocations.length === 0 ? '' : targetLocations.join(',')
    }

    const appliedJobModels = m ? (m as string).split(',') : null
    if (appliedJobModels) {
      const targetJobModels = appliedJobModels.filter((model) => model !== tile.value)
      m = targetJobModels.length === 0 ? '' : targetJobModels.join(',')
    }

    if (tile.type === SearchOptionType.TextQuery) {
      q = ''
    }

    await this.$router.push({
      path: this.$route.path,
      query: { d, l, m, q, }
    })

    setTimeout(() => {
      this.eventBus.emit('app:remove-search-tile', tile)
    }, 0)
  }

  /**
   * Removes all query values from url
   */
  public async clearAllFilters (): Promise<void> {
    await this.$router.push({
      path: this.$route.path
    })

    this.eventBus.emit('app:remove-search-tile')
  }

  /**
   * Returns element label without fixed `└` sign at the beginning
   */
  public rawDepartmentChildLabelTile (label: string): string {
    const elements = label.split(' ')
    return elements[0] === '└'
      ? elements.slice(1, elements.length).join(' ')
      : label
  }

  /**
   * Returns element without count in label e.g. 'IT (2)' -> 'IT'
   */
  public rawDepartmentLabelTile (label: string): string {
    return label.split('(')[0]
  }
}

export default SearchResultsModuleUiPresentation
